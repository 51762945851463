import * as React from "react"
import { Link } from "gatsby"

// styles
const pageStyles = {
	color: "#232129",
	padding: "96px",
	fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
}

const paragraphStyles = {
	marginBottom: 48,
}

// markup
const NotFoundPage = () => {
	return (
		<main style={pageStyles}>
			<title>Stránka nenalezena</title>
			<h1 style={headingStyles}>Bohužel tato stránka neexistuje</h1>
			<p style={paragraphStyles}>
				<Link to="/">Přejít na úvodní stránku</Link>.
			</p>
		</main>
	)
}

export default NotFoundPage